.UnderHeader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background:  */
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%), url('../img/alarmButton.png');
background-repeat: no-repeat, no-repeat, no-repeat;
/* background-attachment: fixed; */
background-size: cover;
  background-position: center;
height: 500px;
color: white;
z-index: 1;
/* transition: 1600ms ease-in; */
transition: 2s opacity;
}
.UnderHeaderFireSecurityDiv h1{
    display: flex;
    text-align: center;
    text-shadow: rgb(0 0 0) 1px 0 10px;
}
.UnderHeader h1{
    text-shadow: rgb(0, 0, 0) 1px 0 10px;
    z-index: 1;
    transition: 3s opacity;
    /* animation: jump 2s ease-in-out infinite; */
  }
  .UnderHeader h2{
    text-shadow: rgb(0, 0, 0) 1px 0 10px;
    z-index: 1;
    transition: 3s opacity;
    /* animation: jump 2s ease-in-out infinite; */
  }
  .blur{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(4, 4, 4, 0.2); 
    backdrop-filter: blur(3px); 
    height: 100%;
      width: 100%;
    z-index: 3;
    /* transition: 1000ms ease-out; */
    /* -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha"(Opacity=45);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=45);
    filter:alpha(opacity=45); */
    transition: 2s opacity;
  }
  .invisible{
    display: none;
  }
  .blur h1{
    animation: jump 2s ease-in;
    filter: blur(3px);
  }
  
  /* @keyframes jump {
    0% {
      transform: translateY(0);
    }
    
    50% {
      transform: translateY(10px);
      filter: blur(15px);
    } */
  /* } */
  .blur h2{
    animation: jump 2s ease-in ;
    filter: blur(3px);
  }

.AboutSecurityBlock{
    display: flex;
    justify-content: center;
    background-color: #000;
    padding-top: 25px;
    padding-bottom: 25px;
}
.LeftInfo{
    display: flex;
    flex-direction: column;
    height: 100%;
    /* width: 30%; */
    margin-left:50px ;
color: #ea6622;
align-items: center;
justify-content: flex-start;
}
.CentralInfo{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 40%;
    min-width: 400px;
    align-items: center;
    
}
.LeftInfo h3{
    border-top: 2px solid #ea6622;
    color: #fff;
    padding-top: 10px;
}
.LeftInfo h4{
    color: white;
    text-align: center;
    font-weight: 200;
    vertical-align: top;
    padding: 0;
    margin: 0;
}
.RightInfo{
    display: flex;
    flex-direction: column;
    /* width: 20%;
    margin-left: 50px;
    margin-right: 50px; */
    width: 45%;
    margin-left: 20px;
    margin-right: 20px;
}
.RightInfo_h3 {
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.RightInfo_h3>h3 {
    padding-top: 15px;
}
.RightInfo_h4 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
}
.RightInfo_h4>h4 {
    /* padding-top: 15px; */
}
.ul_li_rigth {
    padding-left: 15px;
    height: 60%;
}
.ul_li_rigth>ul {
    margin: 0;
}
.RightInfo h3{
    border-top: 2px solid #ea6622;
    color: #fff;
    margin: 0;
}
.RightInfo h4{
    color: white;
    text-align: center;
    font-weight: 200;
    vertical-align: top;
}
.RightInfo ul{
    list-style-position: outside;
    list-style: disc;
    /* color: red */
}
.ul_or_liPlus {
    padding: 0;
}
.RightInfo li{
    color: white;
    margin-bottom: 5px;
}
.RightInfo li::marker{
    color: #ea6622;
}
.kop026img{
    width: 80%;
    background-position: center;
    background-size: cover;
    padding: 0;
    fill: #ff0000d5;
    stroke: black;
    z-index: 11;
    stroke-opacity: 0.7;
    pointer-events: none;
}
.svgKop026{

}

.svgTest{
    z-index: 15;
    fill: #ea6622;
    pointer-events: all;
    stroke:black;
    opacity:0.2;
    stroke-width: 0.5; 
    fill:red;
    transition: ease-in-out 200ms;
}
.svgTest:hover{
    fill: green;
    opacity: 0.3;
}

/* services and prices FS  */
.ServicesAndPricesbox{
    display: flex;
    flex-direction: column;
    background-color: #000;
    width: 100%;
    margin: auto;
}
.divH2{
display: flex;
flex-direction: column;
align-items: center;
padding-top: 30px;
padding-bottom: 30px;
}
.divServices{
    display: flex;
    width: 95%;
    margin: auto;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
    /* background-color: #242426; */
    /* background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url(''); */
    padding: 10px;
    border-radius: 3px;
    overflow-y: auto;
}
.firstPack{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #24252659;
    box-shadow: inset 0px 0px 10px rgb(56 56 56 / 50%);
    margin: 5px;
    border-radius: 3px;
    width: 24%;
    color: #fff;
    padding: 15px;
}
.firstPackH3 {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.firstPackH4 {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.firstPackH4>h4 {
    font-weight: 300;
    font-size: 12px;
}

.firstPackImg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
}
.ulLi_block {
    width: 100%;
    height: 10%; 
    display: flex;
    justify-content: center;
    align-items: center;
}
.h5 {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn_block {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.svgImgBox{
display: flex;
flex-direction: column;
}
.serviceImg{
width: 90%;
/* height: 100%; */
filter: drop-shadow(0 0 0.25rem rgb(0, 0, 0));
}
.svgImg{
width: 60%;
}
.requestButton{
    display: flex;
    border: none;
    color: white;
    background-color: #ea6622;
    padding: 5px;
    border-radius: 3px;
    transition: ease-in-out 200ms;
}
.requestButton:hover{
    scale: 1.1;
    box-shadow:  0px 0px 10px rgba(153, 153, 153, 0.5)
}
.coopContainer {
    display: flex;
    justify-content: center;
}
.logoCoop {
    padding: 20px;
    width: 200px;
    height: 100px;
    display: flex;
    align-items: center;
}
.logoPhoto {
    max-width: 100%;
}
.ul_li_op{
    color: #fff;
    padding: 0;
}

/* для больших мониторов */
@media (min-width: 1600px){
    .serviceImg{
        width: 60%;
/* height: 100%; */
    }
}
@media (max-width: 768px) { 
    .UnderHeader {
        display: flex;
        text-align: center;
        height: 400px;
    }
    .RightInfo{
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        width: 48%;
    }
    .RightInfo_h4 {
        height: 15%;
    }
    .AboutSecurityBlock{
        font-size: 12px;
        display: flex;
        justify-content: space-around;
        background-color: #000;
        padding-top: 25px;
        padding-bottom: 25px;
        height: 250px;
    }
    .divServices {
        display: flex;
        flex-wrap: wrap;
    }
    .firstPack {
        /* width: 38%; */
        width: 90%;
        height: 450px;
    }
    .firstPackImg {
        width: 100%;
        height: 30%; 
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .firstPackH3>h3 {
        font-size: 15px;
    }
    .firstPackH4>h4 {
        font-size: 10px;
    }
    .ul_li_op>li {
        font-size: 11px
    }
    .serviceImg{
        width: 65%;
    }
}