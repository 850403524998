*{
    --speedbtn: 200ms;}
.HeaderTotalDiv{
    display:flex;
    flex-direction: column;
    background-color: black;
    /* height: 100px; */
    color: aliceblue;
}
.HeaderMenu{
    position: relative;
    display: flex;
    flex-direction: row;
    /* margin-top: 40px; */
    /* padding-left:30px;
    padding-right: 30px; */
    border-top: 5px solid #a37b6357;;
    background-color: black;
    height: 50px;
    justify-content: flex-start;
    color: aliceblue;
}
.UpperHeaderPart{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-direction: column; */
    padding: 10px;

}

.navlogo{
    display: flex;
    width: 17%;
}
.acclogo{
    display: flex;
    width: 10%;
    margin-right: 10px;
    /* height: 5%; */
    transition: ease-in-out 200ms;
}
.acclogo:hover{
    scale: 1.1;
}
.PersAccDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    /* width: 100%; */
    justify-content: center;
}
.PersAccDiv p{
    padding: 3px;
    color: #ea6020
}
.MenuElement{
    display: flex;
    align-items: center;
    color: rgb(250, 109, 1);
    padding-left: 10px;
    padding-right: 10px;
    text-shadow: rgb(0 0 0) 2px 0 10px;
    /* border-bottom: 3px solid gray; */
}
.MenuElement:hover{
    display: flex;
    align-items: center;
    color: rgb(250, 109, 1);
    /* border-bottom: 3px solid gray; */
    transition: var(--speedbtn);
    margin-top: -5px;
    border-top: 5px solid gray;
    /* border-image: linear-gradient(45deg, purple, orange) 1; */
    border-image: linear-gradient(90deg, rgba(0,0,0,0.11110381652661061) 1%, rgba(223,129,0,0.7805716036414566) 23%, rgba(221,94,0,0.9262298669467787) 47%, rgba(223,129,0,0.774969362745098) 72%, rgba(0,0,0,0) 100%) 1;
    /* background-color: rgba(211, 211, 211, 0.151); */
    background: linear-gradient(180deg, rgba(247,236,219,0.1) 1%, rgba(0,0,0,0) 100%);
    
}
.HeaderMenu_main{
    display: flex;
}
/* телефон iphone 12pro */
@media (max-width: 768px) {
    .HeaderMenu{
        position: relative;
        display: flex;
        flex-direction: row;
        /* margin-top: 40px; */
        /* padding-left:30px;
        padding-right: 30px; */
        border-top: 5px solid #a37b6357;;
        background-color: black;
        height: 50px;
        justify-content: center;
        color: aliceblue;
    }
    .navlogo{
        width: 30%;
    } 
    .acclogo{
        width: 15%;
    }
}