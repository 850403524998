.UnderHeader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background:  */
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%), url('../img/underHeaderFS_bg.jpeg');
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
    background-position: center;
    height: 500px;
    color: white;
    z-index: 1;
    text-align: center;
    /* transition: 1600ms ease-in; */
    transition: 2s opacity;
}
.UnderHeaderFireSecurityDiv h1{
    display: flex;
    text-align: center;
    text-shadow: rgb(0 0 0) 1px 0 10px;
}
.UnderHeaderFireSecurityDiv h1{
    display: flex;
    text-align: center;
    text-shadow: rgb(0 0 0) 1px 0 10px;
}
.UnderHeader h1{
    text-shadow: rgb(0, 0, 0) 1px 0 10px;
    z-index: 1;
    transition: 3s opacity;
    /* animation: jump 2s ease-in-out infinite; */
  }
  .UnderHeader h2{
    text-shadow: rgb(0, 0, 0) 1px 0 10px;
    z-index: 1;
    transition: 3s opacity;
    /* animation: jump 2s ease-in-out infinite; */
  }
  .blur{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(4, 4, 4, 0.2); 
    backdrop-filter: blur(3px); 
    height: 100%;
      width: 100%;
    z-index: 3;
    /* transition: 1000ms ease-out; */
    /* -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha"(Opacity=45);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=45);
    filter:alpha(opacity=45); */
    transition: 2s opacity;
  }
  .invisible{
    display: none;
  }
  .blur h1{
    animation: jump 2s ease-in;
    filter: blur(3px);
  }
  
  /* @keyframes jump {
    0% {
      transform: translateY(0);
    }
    
    50% {
      transform: translateY(10px);
      filter: blur(15px);
    } */
  /* } */
  .blur h2{
    animation: jump 2s ease-in ;
    filter: blur(3px);
  }

.AboutSecurityBlock{
    display: flex;
    flex-direction: row;
    /* height: 350px; */
    background-color: #000;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
    /* padding-bottom: 50px; */
}
.LeftInfo{
    display: flex;
    flex-direction: column;
    height: 100%;
    /* width: 30%; */
    margin-left:50px ;
color: #ea6622;
align-items: center;
justify-content: flex-start;
}
.CentralInfo{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 40%;
    min-width: 400px;
    align-items: center;
    
}
.LeftInfo h3{
    border-top: 2px solid #ea6622;
    color: #fff;
    padding-top: 10px;
}
.LeftInfo h4{
    color: white;
    text-align: center;
    font-weight: 200;
    vertical-align: top;
    padding: 0;
    margin: 0;
}
.RightInfo{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    /* width: 70%; */
    color: #ea6622;
    margin-left: 40px;
}
.RightInfo h3{
    border-top: 2px solid #ea6622;
    color: #fff;
    padding-top: 10px;
}
.RightInfo h4{
    color: white;
    text-align: center;
    font-weight: 200;
    vertical-align: top;
    padding: 0;
    margin: 0;
    padding-bottom: 30px;
}
.RightInfo ul{
    list-style-position: outside;
    list-style: disc;
    /* color: red */
}
.RightInfo li{
    color: white;
    margin-bottom: 5px;
}
.RightInfo li::marker{
    color: #ea6622;
}
.InformationSecurityDiv_h2 {
    margin-top: 50px;
}
.kop026img{
    width: 80%;
    background-position: center;
    background-size: cover;
    padding: 0;
    fill: #ff0000d5;
    stroke: black;
    z-index: 11;
    stroke-opacity: 0.7;
    pointer-events: none;
}
.svgKop026{

}

.svgTest{
    z-index: 15;
    fill: #ea6622;
    pointer-events: all;
    stroke:black;
    opacity:0.2;
    stroke-width: 0.5; 
    fill:red;
    transition: ease-in-out 200ms;
}
.svgTest:hover{
    fill: green;
    opacity: 0.3;
}

/* services and prices FS  */
.ServicesAndPricesbox{
    display: flex;
    flex-direction: column;
    background-color: #000;
}
.divH2{
display: flex;
flex-direction: column;
align-items: center;
padding-top: 30px;
padding-bottom: 30px;
}
.divServices{
    display: flex;
    /* width: 90%; */
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
    background-color: #242426;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('');
    padding: 10px;
    border-radius: 3px;
    overflow-y: auto;
}
.firstPack{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #24252659;
    box-shadow: inset 0px 0px 10px rgb(56 56 56 / 50%);
    margin: 5px;
    border-radius: 3px;
    width: 24%;
    padding-bottom: 15px;
}
.firstPack h3{
    display: flex;
    justify-content: center;
    color: #fff;
    margin-bottom: 0;
}
.firstPack h4{
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 200;
    color: #fff;
}
.firstPack h5{
    display: flex;
    justify-content: center;
    color: #fff;
}
.firstPack p{
    color: whitesmoke;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
}
.svgImgBox{
display: flex;
flex-direction: column;
}
.serviceImg{
width: 70%;
filter: drop-shadow(0 0 0.25rem rgb(0, 0, 0));
}
.svgImg{
width: 60%;
}
.requestButton{
    display: flex;
    border: none;
    color: white;
    background-color: #ea6622;
    padding: 5px;
    border-radius: 3px;
    transition: ease-in-out 200ms;
}
.requestButton:hover{
    scale: 1.1;
    box-shadow:  0px 0px 10px rgba(153, 153, 153, 0.5)
}
.h3Andh4{
    display: flex;
    flex-direction: column;
    height: 20%;
    justify-content: flex-start;
    }
    .h3Andh4 h4{
        font-size: 12px;
    }
    .imgServ{
        display: flex;
        flex-direction: column;
        height: 20%;
        margin-top: 15px;
        margin-bottom: 15px;
        align-items: center;
        justify-content: space-around;
    }
    .pServ{
        display: flex;
        flex-direction: column;
        height: 25%;
        justify-content: flex-start;
    }
.pServ2{
    display: flex;
    flex-direction: column;
    height: 25%;
    justify-content: center;
    text-align: center;
}
.priceAndBtn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 15%;
}
 /* для больших мониторов */
 @media (min-width: 1600px){
    .svgImg{
        width: 40%;
    }
    .serviceImg{
        width: 40%;
    }
}
/* телефон iphone 12pro */
@media (max-width: 768px) {
    .UnderHeader{
        height: 400px;
    }
    .AboutSecurityBlock{
        flex-direction: column;
    }
    .divServices{
        flex-wrap: wrap;
    }
    .firstPack{
        /* width: 45%; */
        width: 90%;
        justify-content: space-between;
    }
    .firstPack h4{
        font-size: 12px;
    }
    .firstPack p{
        text-align: center;
        font-size: 12px;
    }
    .RightInfo{
        margin-left: 0;
    }
    .RightInfo li{
        font-size: 12px;
    }
    .InformationSecurityDiv_h2{
        margin-top: none;
    }
    .InformationSecurityDiv_h2 h2{
        padding-top: 0;
    }
}