.menuContainer{

}
:root {
    --primary-bg: #242526;
    --secondary-bg: #fff;
    --primary-text-color: #555;
    --secondary-text-color: #cecece;
    --border-radius: 5px;
    --speed: 250ms;
  }
  html {
    scroll-behavior: smooth;
  }
  
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--primary-bg);
  }
  
  code {

  }
  
  ul{
    list-style: none;
    margin-left: 0;
  }
  
  a {
    text-decoration: none;
  }
  
  .menuTrigger p{
    position: absolute;
    top: 20px;
    right: 20px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    background-color: #fff;
    text-shadow: none;
  }
  
  .dropdownMenu{
    position: absolute;
    top: 50px;
    left: 20px;
    background-color: #ffffff;
   
    border-radius: var(--border-radius);
    /* padding: 10px 20px; */
    width: 300px;
    z-index: 6;
  }
  
  /* .dropdownMenu::before{
    content: '';
    position: absolute;
    top: -5px;
    right: 20px;
    height: 20px;
    width: 20px;
    background: var(--secondary-bg);
    transform: rotate(45deg);
  } */
  
  .dropdownMenu.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: var(--speed) ease;
  }
  
  .dropdownMenu.inactive{
    opacity: 0;
    visibility: hidden;
    /* transform: translateY(-20px); */
    transition: var(--speed) ease;
  }
  
  
  h3{
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: var(--primary-text-color);
    /* color: red; */
    line-height: 1.2rem;
    text-shadow: none;
  }
  
  .upperH3{
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-text-color);
    /* color: red; */
    line-height: 1.2rem;
    text-shadow: none;
  }
  h3 span{
    font-size: 14px;
    color: var(--secondary-text-color);
    /* color: red; */
    font-weight: 400;
    text-shadow: none;
  }
  
  .dropdownMenu ul li{
    /* padding: 10px  0; */
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    text-shadow: none;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .dropdownMenu ul li:hover a{
    color: rgb(212, 33, 9);
    cursor: pointer;
  }
  
  .dropdownMenu ul li:hover img{
    opacity: 1;
    cursor: pointer;
  }
  
  .dropdownItem{
    display: flex;
    color: #242526;
    transition: ease-in-out 100ms;
    /* margin: 10px auto; */
  }
  .dropdownItem:hover{
    background-color: rgba(200, 200, 200, 0.169);
    box-shadow: inset 0px 0px 10px rgba(56, 56, 56, 0.151);
  }
  
  .dropdownItem img{
    max-width: 20px;
    margin-right: 10px;
    opacity: 0.5;
    transition: var(--speed);
  }
  
  .dropdownItem a{
    max-width: 100px;
    margin-left: 10px;
    transition: var(--speed);
  }
  .ulList{
    margin-left: 0px;
    margin-inline-start: 0px;
    padding-inline-start: 0px;
  }
   /* телефон iphone 12 pro */
   @media (max-width: 768px) {
    .dropdownMenu{
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 50px;
      left: 40px;
      right: 40px;
      background-color: #ffffff;}

    .dropdownMenu.active{
      opacity: 1;
      visibility: visible;
      /* transform: translateX(8%); */
      /* position: center; */
      transition: var(--speed) ease;
    }
  }