.about{
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    /* height: 120px; */
    background-color: #000;
    position: relative;
    padding: 20px;
    justify-content: space-around;
}

.about h2{
    text-align: center;
    color:white
}
.about p{
    color: white;
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 5px;
    margin-top: 10px;

}
.aboutTexts{
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-evenly
}
.aboutLeft{
    background-color: #24252659;
    box-shadow: inset 0px 0px 10px rgb(56 56 56 / 50%);
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
    margin-bottom: 50px;
    padding: 10px;
    border-radius: 3px;
    /* margin-left: 40px;
    margin-right: 40px; */
}
/* mobile iphone 12pro*/
@media (max-width: 768px) {
    .aboutLeft{
        background-color: #24252659;
        box-shadow: inset 0px 0px 10px rgb(56 56 56 / 50%);
        display: flex;
        flex-direction: column;
        width: 95%;
        margin: auto;
        margin-bottom: 50px;
        padding: 10px;
        border-radius: 3px;
      
    }
    .aboutLeft p{
        font-size: 12px;
    }
}