.UnderHeader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background:  */
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%), url('./sbKomiFireSecurity.jpeg');
background-repeat: no-repeat, no-repeat, no-repeat;
/* background-attachment: fixed; */
background-size: cover;
  background-position: center;
height: 500px;
color: white;
z-index: 1;
/* transition: 1600ms ease-in; */
transition: 2s opacity;
/* backdrop-filter: blur(5px); */

-moz-opacity: 1;
-khtml-opacity: 1;
opacity: 1;
-ms-filter:"progid:DXImageTransform.Microsoft.Alpha"(Opacity=100);
filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
filter:alpha(opacity=100);
transition: opacity .30s ease-in-out;
-moz-transition: opacity .30s ease-in-out;
-webkit-transition: opacity .30s ease-in-out;
}
.UnderHeader h1{
  text-shadow: rgb(0, 0, 0) 1px 0 10px;
  z-index: 1;
  transition: 3s opacity;
  text-align: center;
  /* animation: jump 2s ease-in-out infinite; */
}
.UnderHeader h2{
  text-shadow: rgb(0, 0, 0) 1px 0 10px;
  z-index: 1;
  transition: 3s opacity;
  text-align: center;
  /* animation: jump 2s ease-in-out infinite; */
}
.blur{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(4, 4, 4, 0.2); 
  backdrop-filter: blur(3px); 
  height: 100%;
	width: 100%;
  z-index: 3;
  /* transition: 1000ms ease-out; */
  /* -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha"(Opacity=45);
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=45);
  filter:alpha(opacity=45); */
  transition: 2s opacity;
}
.invisible{
  display: none;
}
.blur h1{
  animation: jump 2s ease-in;
  filter: blur(3px);
}

/* @keyframes jump {
  0% {
    transform: translateY(0);
  }
  
  50% {
    transform: translateY(10px);
    filter: blur(15px);
  } */
/* } */
.blur h2{
  animation: jump 2s ease-in ;
  filter: blur(3px);
}

/* @keyframes jump {
  0% {
    transform: translateY(0);
  }
  
  50% {
    transform: translateY(10px);
    filter: blur(5px);
  }

} */
.line{
  height: 20px;
  background-color: aqua;
}
/* телефон iphone 12pro */
@media (max-width: 768px) {
  .UnderHeader{
    height: 400px;
  }
}
