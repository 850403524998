.benefitsDiv{
    /* display: flex;
    flex-direction: row;
    height: 200px;
    background: rgb(0,2,9);
    background:linear-gradient(180deg, rgb(0 2 9 / 13%) 1%, rgba(0,1,4,0.8618040966386554) 3%, rgba(0,0,0,1) 8%);
        justify-content: space-evenly;
    align-items: center;
    margin-top: -15px; */
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 200px;
        /* width: 70%; */
    background: rgb(0, 0, 0);
    /* background: linear-gradient(180deg, rgb(0 2 9 / 13%) 1%, rgba(0,1,4,0.8618040966386554) 3%, rgba(0,0,0,1) 8%); */
    justify-content: space-evenly;
    align-items: center;
    /* margin-top: -100px; */
    left: 15%;
    z-index: 5;
    /* padding-bottom: 50px; */
}
.benefitDiv{
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* border: 1px solid gray; */
    width: 20%;
    height: 60%;
    z-index: 5;
    border-top: 2px solid #ea6622;
    border-bottom: 2px solid #ea6622; 
}
.benefitDiv h4{
    color: white;
    text-align: center;
}
.benefitDiv h5{
    color: white;
}
.servLogo{
    display: flex;
    height: 70%;
}
.servh3{
    display: flex;
    color: white;
    font-size: 10px;
    text-align: center;
}
.razdelitel{
    width: 95%;
    border: none;
    border: 1px solid orange;
}
/* mobile iphone 12pro*/
@media (max-width: 768px) {
    .benefitsDiv{
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        height: 200px;
        background: rgb(0, 0, 0);
        justify-content: space-evenly;
        align-items: center;
        left: 15%;
        z-index: 5;
        font-size: 12px;
    }
}