.threeColumnsDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: black;
}
.oneOfThreeColumnsDiv{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    width: 30%;
    border-radius: 3px;
    /* background-color: #2525256b; */
}
.header h3{
    color: white;
    padding: 4px;
    /* background-color: #24252659; */
    /* border-radius: 3px; */
    /* box-shadow: inset 0px 0px 10px rgb(56 56 56 / 50%); */
    padding-bottom: 10px;
    border-bottom: 2px solid #ea6622;

}
.header h4{
color: whitesmoke;
    text-align: center;
    font-weight: 200;
    margin-top: 0;
    border-radius: 3px;
}
.oneOfThreeColumnsDiv p{
    color: white;
}
.oneOfThreeColumnsDiv ul{
    list-style-position: outside;
    list-style: disc;
    margin-top: 0;
    /* color: red */
}

.oneOfThreeColumnsDiv li{
    color: white;
    margin-bottom: 5px;

}
.oneOfThreeColumnsDiv li::marker{
    color: #ea6622;
}
.header{
display: flex;
flex-direction: column;

height: 25%;

align-items: center;
}
.list{
    display: flex;
    flex-direction: column;
    height: 35%;
}
.therestpart{
    display: flex;
    flex-direction: column;
    height: 50%;
    font-weight: 300;
}
/* телефон iphone 12pro */
@media (max-width: 768px) {
    .header{
        height: 15%;
        margin: 10px;
    }
    .header h3{
       font-size: 12px;
    }
    .header h4{
        font-size: 12px;
     }
     .oneOfThreeColumnsDiv{
        padding: 5px;
        height: 150px;
     }
     .oneOfThreeColumnsDiv p{
        visibility: hidden;
        font-size: 12px;
        margin-top: 20px;
        
     }
     .therestpart{
        visibility: hidden;
     }
     .oneOfThreeColumnsDiv ul{
        visibility: hidden;
        font-size: 12px;
     }
     .list{
        margin-top: 20px;
     }
}