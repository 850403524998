.licensesBox{
    display: flex;
    flex-direction: column;
    background-color: black;
    padding-bottom: 40px;
    padding-right: 40px;
    padding-left: 40px;
}
.licenseDiv{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.licensesBox h2{
    text-align: center;
}
.licenseListLeftPart{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #2525256b;
    padding: 10px;
    border-radius: 3px;
    width: 50%;
    margin-right: 10px;
}
.licenseListRightPart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #2525256b;
    padding: 10px;
    border-radius: 3px;
    width: 50%;
    margin-left: 10px;
}
.licenseListRightPart h3{
    border-top: 2px solid #ea6622;
    color: #fff;
    padding-top: 10px;
}
.licenseListRightPart h4{
    color: white;
    text-align: center;
    font-weight: 200;
    vertical-align: top;
    padding: 0;
    margin: 0;
}
.licenseListRightPart p{
    color: #fff;
    padding: 10px;
    font-size: 15px;
    font-weight: 200;
    line-height: 1.7;
    -webkit-font-smoothing: subpixel-antialiased;
}
.licenseList{
display: flex;
flex-direction: row;
justify-content: center
}
.oneLicense{
    width: 48%;
margin: 10px;
}
.infoButtonsList{
    display: flex;
    flex-direction: row;
    justify-content: center
}
.licenseButton{
    display: flex;
    border: none;
    color: white;
    align-items: center;
    background-color: #ea6622;
    padding: 5px;
    border-radius: 3px;
    margin: 5px;
    transition: ease-in-out 200ms;
}
.licenseButton:hover{
    scale: 1.02;
    background-color: #ff6b20;
    box-shadow:  0px 0px 5px rgba(255, 255, 255, 0.5)
}
@media (min-width: 1600px){
    .licenseListRightPart p{
        font-size: inherit;
    }
}
/* телефон iphone 12pro */
@media (max-width: 768px) {
    .licenseDiv{
        flex-direction: column;
    }
    .licenseListLeftPart{
        display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #2525256b;
    /* padding: 10px; */
    border-radius: 3px;
    width: 95%;
    }
    .licenseListRightPart{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background-color: #2525256b;
        /* padding: 10px; */
        margin: 0;
        margin-top: 10px;
        border-radius: 3px;
        width: 95%;
    }
    .licenseButton{
        align-items: center;
        font-size: 12px;
    }
    .licenseListRightPart p{
        font-size: 12px;
    }
}