.footer{
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    background-color: black;
    border-top: 2px solid #ea6622;

}
.info_content {
    height: 150px;
    width: 100%;
    display: flex;
    /* margin: auto; */
    padding-top: 20px;
    padding-bottom: 10px;
    justify-content: space-between
}
.picture {
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.services {
    width: 25%;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    align-content: flex-end;
  
    justify-content: flex-start;
}
.information {
    width: 25%;
    display: flex;
    margin-right: 10px;
    flex-direction: column;
    /* flex-wrap: wrap; */
    align-content: flex-end;
    justify-content: flex-start;
}
.picture_block { 
    display: flex;
    width: 35%;
    padding-left: 10px;
}
.picture_block>img {
    display: flex;
    width: 100%;
}
.picture_name {
    margin-top: 25px;
    height: 20%;
    text-align: center;
    color: #f1f1f1;
}
.services_tag_h {
   
    margin-bottom: 25px;
}
.services_tag_h>h3 {
    text-align: left;
    margin: 0;
    color: whitesmoke;
}
.services_link {
    display: flex;
    flex-direction: column;
    font-size: 18px;
}
.services_link>a {
    margin-bottom: 5px;
    font-size: 12px;
    color: #a9a9a9;
    text-decoration: none;

}
.services_link>a:hover {
    color: #ea6622;
}
.information_tag_h {
    margin-bottom: 25px;
    display: flex;
}
.information_tag_h>h3 {
    text-align: left;
    margin: 0;
    color: whitesmoke;
}
.information_link {
    font-size: 18px;
    display: flex;
    flex-direction: column;
}
.information_link>a {
    margin-bottom: 5px;
    text-decoration: none;
    font-size: 12px;
    color: #a9a9a9;
}
.information_link>a:hover {
    color: #ea6622;
}
.company_name {
    display: flex;
    justify-content: center;
    /* background-color: #4b4d4f59; */
    color: whitesmoke;
    /* box-shadow: inset 0px 0px 10px rgb(0 0 0 / 76%); */
    
}
.name_comp {
    /* margin-bottom: 25px; */
    padding: 10px;
}
/* телефон iphone 12pro */
@media (max-width: 768px) {
    .company_name{
       
        visibility: hidden;
    }
    .picture{
         width: 0%;
        visibility: hidden;
    }
    .info_content{
        justify-content: center;
        height: auto;
    }
    .services {
        width: 40%;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
        align-content: flex-end;
      
        justify-content: flex-start;
    }
    .information {
        width: 40%;
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
        align-content: flex-end;
        justify-content: flex-start;
    }
}