.mapH2{
    display: flex;
    justify-content: center;
    background-color: black;
    margin: 0;
    padding: 15px;
}
.mapdiv{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}
/* Прилузский район */
.mapdivPriluz{
display: flex;
flex-direction: column;
position: relative;
text-align: center;
background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/letka.jpeg');;
background-repeat: no-repeat, no-repeat, no-repeat;
background-size: cover;
  background-position: center;
}
/* Сысольский район */
.mapdivSysols{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/vizinga.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: cover;
      background-position: center;
}
/* Корткеросский район */
.mapdivKortkeros{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/kortkeros2.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: cover;
      background-position: center;
}
/* Сыктывкар */
.mapdivSyk{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/syktyvkar2.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}
/* Вуктыл */
.mapdivVyktyl{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/vyktyl.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}
/* Инта */
.mapdivInta{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/inta.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}
/* Усинск */
.mapdivUsinsk{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/usinsk.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}
/* Печора */
.mapdivPechora{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/pechora.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}
/* Княжпогостский */
.mapdivEmva{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/emva.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}
/* сыктывдинский */
.mapdivSyktyvdin{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/syktyvdin.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}
/* Удорский */
.mapdivUdorsky{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/koslan.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}
/* Троицко-Печорск */
.mapdivTroitskopecherski{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/troitskopechorsk.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}

/* Усь-Цильма */
.mapdivUstcilma{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/ustcilma.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}
/* Ижма */
.mapdivIzhma{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/izhma.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}
/* Микунь итд */
.mapdivUstvymsky{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/mikun.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}
/* Койгородок */
.mapdivKoygorodski{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/koygorodok.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}
/* Усть кулом */
.mapdivUstkulomski{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/ustkulom.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}

/* Ухта */
.mapdivUkhta{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/uhta.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}
/* Сосногорск */
.mapdivSosnogorsk{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/sosnogorsk.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
      background-position: center;
    /* height: 500px; */
}
.mapdivVorkuta{
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('./img/vorkuta.jpeg');;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: cover;
      background-position: center;
}
 h2{
    color: rgb(255, 255, 255);
}
.leftAndRight{
    display: flex;
    flex-direction: row;
    height: 100%;
    /* width: 90%; */
    margin-left: 30px;
    /* margin-right: 30px; */
    margin-bottom: 30px;
    justify-content: space-around;
}
.leftPart{
/* display: flex;
flex-direction: column;
height: 100%;
width: 40%; */
display: flex;
flex-direction: column;
height: 100%;
width: 40%;
min-height: 400px;
justify-content: center;
}
.rightPart{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 60%;
}
.chosenPlaceDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    width: 70%;
    background-color: #000000b8;
    border-radius: 3px;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 59%);

    padding-bottom: 20px;
}
.chosenPlaceDiv h3{
    color: whitesmoke;
    font-weight: 300;
}
.chosenPlaceDiv p{
color: rgb(120, 120, 120);
font-weight: 300;
}
.chosenPlaceDiv h4{
    margin-top: 0;
}

.part{
    background-color: orange;
    z-index: 10;
    fill:#ffffffd5;
    stroke:black;
    stroke-opacity: 0.7;
    pointer-events:all;
    transition: .1s ease-in-out;
}
.part:hover{
    scale: 1.005;
    transition: .1s ease-in-out;
    fill: orange;
    z-index: 10;
}
.partChosen{
    scale: 1.005;
    fill: rgb(225, 116, 0); 
    z-index: 10;
    stroke:black;
    stroke-opacity: 0.7;
}
.svgmap{
        max-height: 450px;
        pointer-events: none;
        box-shadow: #ffffffd5;
}
.cities{
    overflow: scroll;
    height: 90px;
    margin: 5px;
    margin-top: 10px;
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 5px;
    /* padding-top: 10px; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    width: 90%;
    align-items: stretch;
    background-color: whitesmoke;

}
.makeRequest{
    width: auto;
    margin-bottom: 10px;
    margin-top: 5px;
    padding: 5px;
    border: none;
    color: white;
    background-color: #ea6622;
    border-radius: 3px;
    transition: ease-in-out 200ms;
}
.makeRequest:hover{
    scale: 1.05;
    box-shadow: 0px 0px 10px rgb(153 153 153 / 50%);
}
h4{
    color: white;
}
.allForInfoAboutPlace{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-items: center;
}
.infoAboutPlaceMenu{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 10px;
    width: 100%;
}
/* .infoAboutPlaceMenu div{
    display: flex;
    background-color: whitesmoke;
    padding: 3px;
    border-radius: 5px;
    border: 0.5px solid lightgray;
} */
.cityInfo{
    display: flex;
    flex-direction: row;
    margin: 3px;
    width: 100%;
    border: 0.5px solid lightgrey;
    border-radius: 3px;
}
.cityName{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 33%;
    padding-left: 5%;
    font-size: 12px;
    font-weight: 200;
}
.cityCount{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
    font-size: 12px;
    font-weight: 300;
}
.cityTime{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
    font-size: 12px;
    font-weight: 200;
}
.mapH2{
    background-color: black;
    text-align: center;
}
.priceside{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-evenly;
}
.pricesideBtn{
    display: flex;
    flex-direction: column;
    width: auto;
    border: none;
    color: white;
    padding: 5px;
    background-color: #ea6622;
    border-radius: 3px;
    transition: ease-in-out 200ms;
}
.pricesideBtn:hover{
    scale: 1.05;
    box-shadow: 0px 0px 10px rgb(153 153 153 / 50%);
}
.rvr{
    display: flex;
    background-color: whitesmoke;
    padding: 3px;
    border-radius: 5px;
    border: 0.5px solid lightgray;
}
.city{
    display: flex;
    background-color: whitesmoke;
    padding: 3px;
    border-radius: 5px;
    border: 0.5px solid lightgray;
}
.kolvo{
    display: flex;
    background-color: whitesmoke;
    padding: 3px;
    border-radius: 5px;
    border: 0.5px solid lightgray;
}

/* телефон iphone 12pro */
@media (max-width: 768px) {
    .leftAndRight{
        display: flex;
    flex-direction: row;
    /* height: 100%; */
    /* width: 90%; */
    margin-left: 0px;
    /* margin-right: 30px; */
    /* margin-bottom: 30px; */
    height: 250px;
    margin-bottom: 0px;
    justify-content: space-around;
    }
    .leftPart{
        width: 90%;
        justify-content: center;
        min-height:0 ;
        margin-left: 10px;
    }
    .rightPart{
        width: 50%;
        justify-content: center
    }
    .rightPart h3{
        font-size: 12px;
    }
    .rightPart p{
        font-size: 12px;
    }
    .chosenPlaceDiv{
        width: 150px;
        height: 220px;
        padding-bottom: 0;
        padding: 5px;
        margin-right: 5px;
        /* margin: 10px; */
    }
    .chosenPlaceDiv h4{
        font-size: 12px;
    }
    .svgmap{
        height: 70%;
    width: 100%;
    scale: 1.4;
    margin-left: 10px;
    margin-right: 10px;
    }
    .infoAboutPlaceMenu div{
        display: flex;
        width: 45%;
        font-size: 5px;
        justify-content: center;
    }
    .cityName{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    width: 50%;
    padding-left: 5%;
    font-size: 8px;
    font-weight: 200;
    }
    .cityCount{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        font-size: 8px;
        font-weight: 300;
    }
    .cityTime{
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    .rvr{
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    .kolvo{
        width: 50%;
    }
    .city{
width: 50%;
    }
}