.feedBackBox{
    display: flex;
    width: 100%;
    flex-direction: column;
    background: #000;
    padding-top: 20px;
    padding-bottom: 20px;
    /* border-top: 2px solid #ea6622; */
    background-color: #4b4d4f59;
    color: whitesmoke;
    box-shadow: inset 0px 0px 10px rgb(0 0 0 / 76%);
    
}
.upperFBDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
   
.lowerFBDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.feedBackForm{
    height: 100%;
    width: 100%;
    display: flex;
    /* justify-content: space-evenly; */
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    padding: 10px;
    justify-content: center;
  
    /* margin-left: 40px; */
    /* margin-right: 40px; */
}
.consultLogo{
    display: flex;
    width: 20%;
    /* display: contents; */
    width: 50px;
    margin-right: 15px;
}
.feedBackForm_input>input {
    /* border: 2px solid #ea6622; */
    /* width: 300px; */
    width: 17%;
    height: 30px;
    font-size: 11px;
    text-align: center;
    border-radius: 3px;
    margin-right: 15px;
    border: 0;

}
/* .feedBackForm_input>input:focus {
    outline:none;
} */
.feedBackForm_input>input:focus {
    outline-color:#ea6622;
}
.feedBackForm_btn>button {
    /* width: 300px; */
    width: 17%;
    height: 35px;
    border: none;
    color: white;
    background-color: #ea6622;
    border-radius: 3px;
    transition: ease-in-out 200ms;
}
.feedBackForm_btn>button:hover{
    scale: 1.1;
    box-shadow:  0px 0px 10px rgba(153, 153, 153, 0.5)
}
.feedBackForm_img {
    width: 20%;
    display: contents;
    margin: 20px;
    
}
.feedBackForm_input {
    width: 20%;
    display: contents;
    margin: 20px;
}
.feedBackForm_btn {
    width: 20%;
    display: contents;
    margin: 20px;
}
/* телефон iphone 12pro */
@media (max-width: 768px) {
    .feedBackForm{
        flex-direction: column;
    }
    .feedBackForm_input>input{
        width: 40%;
        margin: 5px;
        height: 30px;
    }
    .feedBackForm_btn>button{
        width: 35%;
        margin: 5px;
        height: 35px;
    }
}