
.UnderHeaderSecurity{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background:  */
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%), url('../img/alarm-systems.jpeg');
background-repeat: no-repeat, no-repeat, no-repeat;
/* background-attachment: fixed; */
background-size: cover;
  background-position: center;
height: 500px;
color: white;
z-index: 1;
/* transition: 1600ms ease-in; */
transition: 2s opacity;
}
/* .UnderHeaderSecurity h1{
    display: flex;
    text-align: center;
    text-shadow: rgb(0 0 0) 1px 0 10px;
} */
.UnderHeaderSecurity h1{
    text-shadow: rgb(0, 0, 0) 1px 0 10px;
    z-index: 1;
    text-align: center;
    transition: 3s opacity;
    /* animation: jump 2s ease-in-out infinite; */
  }
  .UnderHeaderSecurity h2{
    text-shadow: rgb(0, 0, 0) 1px 0 10px;
    z-index: 1;
    transition: 3s opacity;
    /* animation: jump 2s ease-in-out infinite; */}
.blur{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(4, 4, 4, 0.2); 
    backdrop-filter: blur(3px); 
    height: 100%;
      width: 100%;
    z-index: 3;
    /* transition: 1000ms ease-out; */
    /* -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha"(Opacity=45);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=45);
    filter:alpha(opacity=45); */
    transition: 2s opacity;
  }
  .invisible{
    display: none;
  }
  .blur h1{
    animation: jump 2s ease-in;
    filter: blur(3px);
  }
  .blur h2{
    animation: jump 2s ease-in ;
    filter: blur(3px);
  }
/* About upper block */

.AboutSecurityBlock{
    display: flex;
    flex-direction: row;
    /* height: 350px; */
    background-color: #000;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 50px;
}
.LeftInfo{
    display: flex;
    flex-direction: column;
    height: 100%;
    /* width: 30%; */
    margin-left:50px ;
color: #ea6622;
align-items: center;
justify-content: flex-start;
}
.CentralInfo{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 40%;
    min-width: 400px;
    align-items: center;
    
}
.LeftInfo h3{
    border-top: 2px solid #ea6622;
    color: #fff;
    padding-top: 10px;
}
.LeftInfo h4{
    color: white;
    text-align: center;
    font-weight: 200;
    vertical-align: top;
    padding: 0;
    margin: 0;
}
.RightInfo{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    /* width: 70%; */
    color: #ea6622;
    margin-left: 40px;
}
.RightInfo h3{
    border-top: 2px solid #ea6622;
    color: #fff;
    padding-top: 10px;
}
.RightInfo h4{
    color: white;
    text-align: center;
    font-weight: 200;
    vertical-align: top;
    padding: 0;
    margin: 0;
    padding-bottom: 30px;
}
.RightInfo ul{
    list-style-position: outside;
    list-style: disc;
    /* color: red */
}
.RightInfo li{
    color: white;
    margin-bottom: 5px;
}
.RightInfo li::marker{
    color: #ea6622;
}
.kop026img{
    width: 80%;
    background-image: url('../img/kop026.png');
    background-position: center;
    background-size: cover;
    padding: 0;
    fill: #ff0000d5;
    stroke: black;
    z-index: 11;
    stroke-opacity: 0.7;
    pointer-events: none;
}
.svgKop026{

}

.svgTest{
    z-index: 15;
    fill: #ea6622;
    pointer-events: all;
    stroke:black;
    opacity:0.2;
    stroke-width: 0.5; 
    fill:red;
    transition: ease-in-out 200ms;
}
.svgTest:hover{
    fill: green;
    opacity: 0.3;
}


/* ServicesAndPrices */
.ServicesAndPricesbox{
    display: flex;
    flex-direction: column;
    background-color: #000;
}
.divH2{
display: flex;
flex-direction: column;
align-items: center;
padding-top: 30px;
padding-bottom: 30px;
}
.divServices{
    display: flex;
    /* width: 90%; */
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
    background-color: #242426;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('');
    padding: 10px;
    border-radius: 3px;
    overflow-y: auto;
}
.firstPack{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: #24252659;
    box-shadow: inset 0px 0px 10px rgb(56 56 56 / 50%);
    margin: 5px;
    border-radius: 3px;
    width: 24%;
    height: 450px;
    padding-bottom: 15px;
}
.firstPack h3{
    color: #fff;
}
.firstPack p{
    color: whitesmoke;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
}
.svgImgBox{
display: flex;
flex-direction: column;
}
.serviceImg{
width: 60%;
filter: drop-shadow(0 0 0.25rem rgb(0, 0, 0));
}
.svgImg{
width: 60%;
}
.requestButton{
    display: flex;
    border: none;
    color: white;
    background-color: #ea6622;
    padding: 5px;
    border-radius: 3px;
    transition: ease-in-out 200ms;
}
.requestButton:hover{
    scale: 1.1;
    box-shadow:  0px 0px 10px rgba(153, 153, 153, 0.5)
}
/* PartnershipStages */
.PartnershipBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: black;
}
.PartnershipBox h2{
    text-align: center;
}
.PartnershipStagesBox{
    display: flex;
    flex-direction: row;
    height: 100px;
    background-color: black;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.stagebox{
display: flex;
flex-direction: column;
align-items: center;
text-align: center;

}
.stagebox>img {
    height: 100%;
}

.stagebox p{
font-size: 10px;
   color: white;
    }
.stage{
    width: 60%;
}
.stageChosen{
    width: 67%;
    filter: drop-shadow(0 0 0.25rem rgba(223, 91, 10, 0.712));
}

.stage:hover{
    width: 67%;
    transition: ease-in-out 200ms;
}
.aboutStage{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    padding: 15px;
    height: 200px;
    justify-content: flex-start;
    color: #fff;
    background-color: #24252659;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 50px;
    box-shadow: inset 0px 0px 10px rgb(56 56 56 / 50%);
    border-radius: 5px;
}
.aboutStage h5{
    text-align: center;
}
.emptyAboutStage{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    padding: 15px;
    /* height: 200px; */
    justify-content: space-between;
    color: #fff;
    background-color: #24252659;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 50px;
    box-shadow: inset 0px 0px 10px rgb(56 56 56 / 50%);
    border-radius: 5px;
}
.twoButtons{
    display: flex;
    flex-direction: row;
}
.feedBackBtn{
    display: flex;
    border: none;
    color: white;
    background-color: #ea6622;
    padding: 5px;
    border-radius: 3px;
    margin: 5px;
    transition: ease-in-out 200ms;
}
.feedBackBtn:hover{
    scale: 1.02;
    background-color: #ff6b20;
    box-shadow: 0px 0px 5px rgb(255 255 255 / 50%);
}

/* InformationSecurity */
.InformationSecurityDiv{
    display: flex;
    flex-direction: column;
    color: white;
    background-color: #000;
}
.InformationSecurityDiv h2{
    text-align: center;
    padding-top: 30px;
}
.InformationSecurityDiv p{
    padding-left: 30px;
    padding-right: 30px;
}
.alarmSecurityDetails{
    display: flex;
    flex-direction: row;
}
.InformationSecurityDiv_divP {
    background-color: #24252659;
    box-shadow: inset 0px 0px 10px rgb(56 56 56 / 50%);
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
    margin-bottom: 50px;
    padding: 10px;
     /* font-size: 24px; */
     border-radius: 3px;
}
.InformationSecurityDiv_divP p{
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 5px;
    margin-top: 10px;
}
.InformationSecurityDiv_h2 {
    margin-top: 50px;
}
.h3Andh4{
    display: flex;
    flex-direction: column;
    height: 15%;
    justify-content: flex-start;
    }
    .h3Andh4 h4{
        font-size: 12px;
    }
    .imgServ{
        display: flex;
        flex-direction: column;
        height: 35%;
        margin-top: 15px;
        margin-bottom: 15px;
        align-items: center;
        justify-content: space-around;
    }
    .pServ{
        display: flex;
        flex-direction: column;
        height: 25%;
        justify-content: flex-start;
    }
    .pServ2{
        display: flex;
        flex-direction: column;
        height: 25%;
        justify-content: center;
        text-align: center;
    }
    .priceAndBtn{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        height: 25%;
    }
    /* для больших мониторов */
    @media (min-width: 1600px){
        .svgImg{
            width: 40%;
        }
        .serviceImg{
            width: 40%;
        }
    }
/* телефон iphone 12pro */
@media (max-width: 768px) {
    .UnderHeaderSecurity{
        height: 400px;
    }
    .divServices{
        flex-wrap: wrap;
    }
    .RightInfo{
        margin-left: 0;
    }
    .RightInfo li{
        font-size: 12px;
    }
    .firstPack{
        /* width: 45%; */
        width: 90%;
        justify-content: space-between;
    }
    .firstPack p{
        font-size: 12px;
    }
    .InformationSecurityDiv_divP{
        background-color: #24252659;
        box-shadow: inset 0px 0px 10px rgb(56 56 56 / 50%);
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: auto;
        margin-bottom: 0px;
        padding: 10px;
        border-radius: 3px;
    }
    .InformationSecurityDiv_divP p{
        font-size: 12px;
    }
    .AboutSecurityBlock{
        flex-direction: column;
        padding-bottom: 10px;
    }
    .InformationSecurityDiv_h2{
        margin-top: 0px;
    }
    .InformationSecurityDiv_h2 h2{
        padding-top: 0;
    }
    .imgServ{
        display: flex;
        flex-direction: column;
        height: 45%;
        /* margin-top: 15px;
        margin-bottom: 15px; */
        width: 100%;
        align-items: center;
        justify-content: space-around;
    }
    .PartnershipBox{
        display: none;
    }
}
