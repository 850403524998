/* @import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300;700&family=Open+Sans:wght@600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500&family=Nanum+Gothic:wght@700;800&display=swap');
body {
  margin: 0;
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {

}
/* для больших мониторов */
@media (min-width: 1600px){
  *{
    /* font-size: 16px */
    font-size: 1.2vw;
/* height: 100%; */
  }
  h4{
    font-size: 1.3vw;
  }
}