.container {
    min-height: 800px;
    height: auto;
    padding-bottom: 50px;
    flex-shrink: 0;
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f7;
    border-radius: 3px;
}
.contacts_modules {
    width: 60%;
    height: 600px;
    background-color: #f1f1f1;
    display: flex;
    border-radius: 3px;
    flex-direction: column;
}
.contacts_map {
    /* display: flex; */
    width: 100%;
    height: 50%;
}
.contacts_text {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.contacts_text_phone{
    margin-bottom: 25px;
    color: #ff8562;
}
.contacts_text_phone>a {
    font-size: 25px;
    outline: none;
    color: #ff8562;
}
.contacts_text_phone>a:active {
    color: #ff8562;
}
.contacts_text_email{
    margin-bottom: 25px;
    color: #ff8562;
}
.contacts_text_email>a {
    font-size: 25px;
    outline: none;
    color: #ff8562;
}
.contacts_text_email>a:active {
    color: #ff8562;
}
.contacts_text_adress{
    margin-bottom: 25px;
}
.contacts_text_adress>h2{
    color: black;
}
.contacts_text_working>h2{
    color: black;
}
.YMaps {
    width: 600px;
    height: 400px;
}
.preLoader{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
@media (max-width: 768px) {

    .contacts_modules {
        width: 90%;
        height: 600px;
        background-color: #f1f1f1;
        display: flex;
        border-radius: 3px;
        flex-direction: column;
        font-size: 10px;
    }
    .contacts_text_phone>a {
        font-size: 17px;
        outline: none;
        color: #ff8562;
    }
    .contacts_text_email>a {
        font-size: 17px;
        outline: none;
        color: #ff8562;
    }
    .container { 
        min-height: 480px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}