.specH2{
    display: flex;
    justify-content: center;
    background-color: black;
    margin: 0;
    padding: 15px;
}
.allAboutSpecialisation{
    display: flex;
    flex-direction: row;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 48%, rgba(0,0,0,1) 100%), url('./img/background.jpeg');
background-repeat: no-repeat, no-repeat, no-repeat;
/* background-attachment: fixed; */
background-size: cover;
  background-position: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.aboutRight{
    display: flex;
    width: 45%;
    flex-direction: column;
    justify-content: flex-start;
}
.rightAll{
    display: flex;
    flex-direction: column;
    color: white;
    background-color: #000000b8;
    padding: 10px;
    justify-content: space-around;
    height: 300px;
    border-radius: 5px;
    /* height: 100%; */
    /* justify-content: center; */
    align-items: center;
    width: 100%;
}
.rightAll h3{
    color: whitesmoke;
}
.rightList{
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    height: 80%;
    margin-top: 5px;
    padding-top: 10px;
    border-radius: 10px;
    overflow: auto;
}
.rightList a{
    padding: 3px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    background-color: white;
    font-size: 12px;
    border: 0.5px solid lightgray;
}
.aboutleft{
    display: flex;
    flex-direction: column;
    width: 45%;
    padding-left: 40px;
}
.notChosen{
    color: white;
    text-align: start;
    transition: ease-in-out 100ms;
}
.notChosen:hover{
    color: #ea6622;
    text-shadow: rgb(0 0 0) 1px 0 10px;
    transition: ease-in-out 100ms;
}
.chosen{
    color: #ea6622;
    text-align: start;
    text-shadow: rgb(0 0 0) 1px 0 10px;
    transition: ease-in-out 100ms;
}
/* RightComponent */

.ServicesAndPricesbox{
    display: flex;
    flex-direction: column;
    background-color: #000;
}
.divH2{
display: flex;
flex-direction: column;
align-items: center;
padding-top: 30px;
padding-bottom: 30px;
}
.divServices{
    display: flex;
    /* width: 90%; */
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
    background-color: #242426;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 48%, rgba(0,0,0,1) 100%), url('');
    padding: 10px;
    border-radius: 3px;
    overflow-y: auto;
}
.firstPack{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: #24252659;
    box-shadow: inset 0px 0px 10px rgb(56 56 56 / 50%);
    margin: 5px;
    border-radius: 3px;
    width: 24%;
    padding-bottom: 15px;
}
.firstPack h3{
    color: #fff;
}
.firstPack p{
    color: whitesmoke;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
}
.imgBox{
display: flex;
flex-direction: column;
align-items: center;
height: 65%;
}
.serviceImg{
    display: flex;
    flex-direction: column;
height: 100%;
filter: drop-shadow(0 0 0.25rem rgb(0, 0, 0));
}
.svgImg{
width: 50%;
}
.requestButton{
    display: flex;
    border: none;
    color: white;
    background-color: #ea6622;
    padding: 5px;
    border-radius: 3px;
    transition: ease-in-out 200ms;
}
.requestButton:hover{
    scale: 1.1;
    box-shadow:  0px 0px 10px rgba(153, 153, 153, 0.5)
}
.moreAboutSpec{
    display: flex;
    border: none;
    color: white;
    background-color: #ea6622;
    padding: 5px;
    border-radius: 3px;
    margin: 5px;
    margin-top: 20px;
    transition: ease-in-out 200ms;
}
.moreAboutSpec:hover{
    scale: 1.03;
    background-color: #ff6b20;
    box-shadow:  0px 0px 10px rgba(153, 153, 153, 0.5)
}
/* телефон iphone 12pro */
@media (max-width: 768px) {
    .aboutleft{
        display: flex;
        flex-direction: column;
        width: 45%;
        padding-left: 20px;
    }
    .aboutRight{
        justify-content: center;
    }
    .notChosen{
        color: white;
        text-shadow: rgb(0 0 0) 1px 0 10px;
        text-align: start;
        font-size: 12px;
        transition: ease-in-out 100ms;
    }
    .notChosen:hover{
        color: #ea6622;
        text-shadow: rgb(0 0 0) 1px 0 10px;
        transition: ease-in-out 100ms;
    }
    .chosen{
        color: #ea6622;
        text-align: start;
        font-size: 12px;
        text-shadow: rgb(0 0 0) 1px 0 10px;
        transition: ease-in-out 100ms;
    }
    .rightAll{
        display: flex;
        flex-direction: column;
        color: white;
      background-color: #000000b8;;
        padding: 10px;
        justify-content: space-around;
        height: 230px;
        border-radius: 5px;
        /* height: 100%; */
        align-items: center;
        width: 80%;
    }
    .imgBox{
        justify-content: center
    }
    .rightAll h3{
        font-size: 12px;
    }
    .allAboutSpecialisation{
        justify-content: space-around
    }
    .serviceImg{
        display: flex;
        flex-direction: column;
    width: 80%;
    height: auto;
    filter: drop-shadow(0 0 0.25rem rgb(0, 0, 0));
    }
}